import { Checkbox } from '@mui/material'
import {
   CheckBoxList,
   CheckBoxTitle,
   CheckBoxWrap,
   InputWrap,
   TopInputsWrap,
   selectStyles
} from './styled'
import { useTranslation } from 'react-i18next'
import { TTopinputsProps } from './types'
import { Controller } from 'react-hook-form'
import { categoryActions, getMainCategorySelector } from 'store'
import Select from 'react-select'
import { useTypedSelector } from 'hooks'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

export const TopInputs = ({ errors, control, watch, setValue }: TTopinputsProps) => {
   const { t } = useTranslation()

   const dispatch = useDispatch()

   const { categories } = useTypedSelector(getMainCategorySelector)

   useEffect(() => {
      !categories.data.length &&
         dispatch(
            categoryActions.getCategories({
               limit: 1000,
               value: null,
               field: 'productsTotalCount',
               hidden: 'false'
            })
         )
   }, [])

   const options = useMemo(
      () =>
         categories.data.map((category) => ({
            label: category.title,
            value: category._id
         })),
      [categories]
   )
   const onSelectChange = () => {
      watch(`questions`).map((item, index) =>
         item.answers.forEach((answer, subIndex) => {
            if (
               answer.action.value === 'products' ||
               answer.action.value === 'question&products'
            ) {
               setValue(
                  `questions.${index}.answers.${subIndex}.answerProductsData`,
                  undefined
               )
            }
         })
      )
   }
   return (
      <TopInputsWrap>
         <InputWrap>
            <p>{t('questionsCategoryName')}</p>
            <Controller
               control={control}
               rules={{ required: t('required.field') }}
               name="nameCategory"
               render={({ field: { value, onChange } }) => (
                  <Select
                     placeholder={t('select.category')}
                     styles={selectStyles}
                     onChange={(value) => {
                        onSelectChange()
                        onChange(value)
                     }}
                     value={value}
                     options={options}
                     menuPortalTarget={document.body}
                  />
               )}
            />
            {errors?.nameCategory?.message && (
               <span>{errors?.nameCategory?.message}</span>
            )}
         </InputWrap>
         <CheckBoxWrap>
            <CheckBoxTitle>{t('roomsType')}</CheckBoxTitle>
            <CheckBoxList>
               <li>
                  <Controller
                     control={control}
                     render={({ field: { value, onChange } }) => (
                        <Checkbox onChange={onChange} checked={value || false} />
                     )}
                     name="typeofrooms.corridor"
                  />
                  <p>{t('corridor')}</p>
               </li>
               <li>
                  <Controller
                     control={control}
                     render={({ field: { value, onChange } }) => (
                        <Checkbox onChange={onChange} checked={value || false} />
                     )}
                     name="typeofrooms.livingRoom"
                  />
                  <p>{t('livingRoom')}</p>
               </li>
               <li>
                  <Controller
                     control={control}
                     render={({ field: { value, onChange } }) => (
                        <Checkbox onChange={onChange} checked={value || false} />
                     )}
                     name="typeofrooms.bathroom"
                  />
                  <p>{t('bathroom')}</p>
               </li>
               <li>
                  <Controller
                     control={control}
                     render={({ field: { value, onChange } }) => (
                        <Checkbox onChange={onChange} checked={value || false} />
                     )}
                     name="typeofrooms.bedroom"
                  />
                  <p>{t('bedroom')}</p>
               </li>
               <li>
                  <Controller
                     control={control}
                     render={({ field: { value, onChange } }) => (
                        <Checkbox onChange={onChange} checked={value || false} />
                     )}
                     name="typeofrooms.kitchen"
                  />
                  <p>{t('kitchen')}</p>
               </li>
               <li>
                  <Controller
                     control={control}
                     render={({ field: { value, onChange } }) => (
                        <Checkbox onChange={onChange} checked={value || false} />
                     )}
                     name="typeofrooms.other"
                  />
                  <p>{t('other')}</p>
               </li>
            </CheckBoxList>
         </CheckBoxWrap>
      </TopInputsWrap>
   )
}
